// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-html-js": () => import("./../src/pages/privacy.html.js" /* webpackChunkName: "component---src-pages-privacy-html-js" */),
  "component---src-templates-slide-js": () => import("./../src/templates/slide.js" /* webpackChunkName: "component---src-templates-slide-js" */)
}

